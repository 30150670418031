import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const RailProfile = () => (
  <div className="h-full overflow-auto">
    <div className="mb-16">
      <RowContainer lg full>
        <AvatarContainer lg full>
          <Avatar lg />
          <RowContainer full>
            <Row lg half />
            <Row lg almostFull />
            <div className="pt-4">
              <AvatarContainer lg full>
                <Row xxl long />
                <Row xxl long />
              </AvatarContainer>
            </div>
          </RowContainer>
        </AvatarContainer>
      </RowContainer>
    </div>
    <RowContainer full>
      <Row lg almostFull />
      <Row lg full />
      <Row lg full />
      <Row lg half />
    </RowContainer>
  </div>
);
