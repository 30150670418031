import {
  Avatar,
  AvatarContainer,
  Row,
  RowContainer,
} from "@circle-react-uikit/SkeletonLoader/components";

export const RailMemberList = () => (
  <div className="h-full overflow-auto">
    <div className="px-2">
      <RowContainer lg full>
        {Array.from(Array(10).keys()).map(index => (
          <div key={index}>
            <AvatarContainer md full>
              <Avatar />
              <RowContainer full>
                <Row lg almostFull />
              </RowContainer>
            </AvatarContainer>
          </div>
        ))}
      </RowContainer>
    </div>
  </div>
);
